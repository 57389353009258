export const courierArr = [
    {
        name:'Torrent Managed Courier Service',
        value: 'Torrent Managed Courier Service'
    },
    {
        name:'Client Pickup',
        value: 'Client Pickup'
    }
];

export const projectMatrixArr = [
    {
        name:'Soil',
        value: 'soil'
    },
    {
        name:'Ground Water',
        value: 'Ground Water'
    },
    {
        name:'Surface Water',
        value: 'Surface Water'
    },
    {
        name:'Soil Vapor',
        value: 'Soil Vapor'
    },
    {
        name:'Ambient Air',
        value: 'Ambient Air'
    },
    {
        name:'Wipe',
        value: 'Wipe'
    },
    {
        name:'Pure Product',
        value: 'Pure Product'
    },
    {
        name:'Sludge',
        value: 'Sludge'
    },
    {
        name:'Well Water',
        value: 'Well Water'
    }
];