import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private httpClient: HttpClient,
    private localStorageService: LocalStorageService) { }

  /**
   * @description - Http call to get the product list
   * @returns 
   */
  getProducts(data: any) {
    const userData: any = this.localStorageService.getData('userDetails');
    const shopID = JSON.parse(userData).shop_id
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/productManagement?shopId=${shopID}&searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`;
    return this.httpClient.get(url, {
      headers: headers,
    }).toPromise();
  }

  
  /**
   * @description - Http call to get the product list
   * @returns 
   */
  getInventory(data: any) {
    const userData: any = this.localStorageService.getData('userDetails');
    const shopID = JSON.parse(userData).shop_id
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    const url = `${environment.apiUrl}/admin/inventoryListWithSearch?shopId=${shopID}&searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`;
    return this.httpClient.get(url, {
      headers: headers,
    }).toPromise();
  }

  /**
   * @description - Http call to get the product master data
   * @returns 
   */
  getProductMasterData() {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient.get(`${environment.apiUrl}/order/getMasterData`, {
      headers: headers,
    }).toPromise();
  }

    /**
   * @description - Http call to get the product master data
   * @returns 
   */
    getProductCategory() {
      const userData: any = this.localStorageService.getData('userDetails');
      const shopID = JSON.parse(userData).shop_id
      const token: any = this.localStorageService.getData('token');
      const headers = new HttpHeaders({
        auth: `${token}`,
      });
      return this.httpClient.get(`${environment.apiUrl}/admin/getProductCategory?shopId=${shopID}`, {
        headers: headers,
      }).toPromise();
    }

  /**
   * @description - Http call to manage the product
   * @param productData - product data
   * @returns 
   */
  manageProduct(productData: any) {
    const userData: any = this.localStorageService.getData('userDetails');
    const shopID = JSON.parse(userData).shop_id
    productData.shopId = shopID
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient.post(`${environment.apiUrl}/admin/addProduct`, productData, {
      headers: headers,
    }).toPromise();
  }

  
  /**
   * @description - Http call to manage the product
   * @param productData - product data
   * @returns 
   */
  addInventory(productData: any) {
    console.log(">>>>>>>>>>>>>>>",productData);
    const userData: any = this.localStorageService.getData('userDetails');
    const shopID = JSON.parse(userData).shop_id
    productData.shopId = shopID
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient.post(`${environment.apiUrl}/admin/addInventory`, productData, {
      headers: headers,
    }).toPromise();
  }

  /**
 * @description - Http call to manage the product
 * @param productData - product data
 * @returns 
 */
  deleteProduct(productData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    console.log(productData);
    return this.httpClient.post(`${environment.apiUrl}/admin/deleteProduct`, productData, {
      headers: headers,
    }).toPromise();
  }

  /**
  * @description - Http call to get the product master data
  * @returns 
  */
  getCompanyList(keyword: string) {
    return this.httpClient.get(`${environment.apiUrl}/order/getCompanies?searchKeyword=${keyword}`).toPromise();
  }
}
