import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectManagementService {
  constructor(
    private httpClient: HttpClient,
    private localStorageService: LocalStorageService
  ) {}

  /**
   * @description - Http call to get the order list for project management screen
   * @returns
   */
  getOrderList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/order/orderList?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order history fagement screen
   * @returns
   */
  getPmHistory(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/projectManagement/getPmHistory?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the Ready to ship list for project management screen
   * @returns
   */
  getReadyToShipList(data: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/projectManagement/getListOfReadyToShipItems?searchKeyword=${data.searchKeyword}&skipRecord=${data.skipRecord}&pageSize=${data.pageSize}&sortBy=${data.sortBy}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order details
   * @param orderId - order id
   * @returns
   */
  getOrderDetails(orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(`${environment.apiUrl}/order/getOrderDetails?orderId=${orderId}`, {
        headers: headers,
      })
      .toPromise();
  }

  /**
   * @description - Http call to create bottle order
   * @param orderData - order data
   */
  createBottleOrder(orderData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/projectManagement/createBottleOrder`,
        orderData,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to approve and ship order
   * @param orderData - order data
   */
  approveAndShip(orderData: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      auth: `${token}`,
    });
    return this.httpClient
      .post(
        `${environment.apiUrl}/projectManagement/approveAndShip`,
        orderData,
        {
          headers: headers,
        }
      )
      .toPromise();
  }

  /**
   * @description - Http call to get the order pdf
   * @returns
   */
  getOrderPdf(orderId: any) {
    const token: any = this.localStorageService.getData('token');
    const headers = new HttpHeaders({
      auth: `${token}`,
    });
    return this.httpClient
      .get(
        `${environment.apiUrl}/airLogistics/orderShippingDetailPdf?orderId=${orderId}`,
        {
          headers: headers,
        }
      )
      .toPromise();
  } 
  
}
